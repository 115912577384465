import ru from '@/assets/locales/ru.json';
import kk from '@/assets/locales/kk.json';
import en from '@/assets/locales/en.json';
import kzFlag from '@/assets/icons/kz-flag.svg';
import ruFlag from '@/assets/icons/ru-flag.svg';
import enFlag from '@/assets/icons/en-flag.svg';

export default [
	{
		name: 'Қазақ тілі',
		code: 'kk',
		label: 'Қаз',
		messages: kk,
		icon: kzFlag
	},
	{
		name: 'Русский язык',
		code: 'ru',
		label: 'Рус',
		messages: ru,
		icon: ruFlag
	},
	{
		name: 'English',
		code: 'en',
		label: 'Eng',
		messages: en,
		icon: enFlag
	}
] as const;
