import { useRouter } from 'vue-router';
import { MenuService } from '@/services/menu';

export default function () {
	const router = useRouter();

	/**
	 * Переходит на предыдущие страницы
	 */
	const goToPrevPage = (delta = -1) => {
		if (document.referrer.includes('chocopay-web')) {
			router.push('/');
		} else {
			const currentPath = window.location.href;
			router.go(delta);

			setTimeout(() => {
				if (window.location.href === currentPath) {
					router.push('/');
				}
			}, 500);
		}
	};

	/**
	 * Переходит на главную страницу
	 */
	const goToHomePage = () => {
		const source = MenuService.state.tempFilialData.source;
		const filialId = MenuService.state.tempFilialData.filialId;

		if (source && filialId) {
			return router.push({ name: 'Menu', params: { filial_id: filialId } });
		}

		return router.push({ name: 'Home' });
	};

	return { goToPrevPage, goToHomePage };
}
