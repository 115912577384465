import { client } from '../../default.client';
import { JSON_API_HEADER } from '../../constants';
import { Subsidy } from '@/types/interfaces/menu';
import { ApiProxyResponse } from '../../types/interfaces';

/**
 * Возвращает субсидии по филиалу
 */
export default function (filialId: number): Promise<Subsidy> {
	const params = {
		'terminal_ids[]': filialId,
		only_active: true
	};

	const config = {
		headers: JSON_API_HEADER,
		params
	};

	return client
		.get<ApiProxyResponse<Subsidy[]>>('menu-promos/v1/promos/subsidies', {
			...config
		})
		.then(response => response.data.data[0])
		.catch(error => {
			throw error;
		});
}
