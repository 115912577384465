import { createI18n } from 'vue-i18n';
import { LANG_STORAGE_KEY } from './constants';
import getStartingLocale from './get-starting-locale';
import { LANGUAGES } from '@/utils/constants';
import { pluralizeRu } from '@/utils/functions';

export type Locale = 'ru' | 'kk' | 'en';

/**
 * Создание экземпляра i18n с подгрузкой языка из localStorage
 */
const i18n = createI18n({
	legacy: false,
	locale: getStartingLocale(),
	globalInjection: true,
	fallbackLocale: 'ru',
	pluralRules: {
		ru: pluralizeRu
	},
	messages: LANGUAGES.reduce<Record<string, Record<string, string | object>>>(
		(acc, lang) => {
			if (!acc[lang.code]) {
				acc[lang.code] = lang.messages;
			}

			return acc;
		},
		{}
	)
});

/**
 * Изменение языка глобально и в хранилище клиента
 */
export function setLocaleToStorage(value: string) {
	localStorage.setItem(LANG_STORAGE_KEY, value);
}

export function needToDetermineLanguage() {
	return !localStorage.getItem(LANG_STORAGE_KEY);
}

export default i18n;
