import { ComputedRef } from 'vue';
import { ICoordinates } from '@/types/interfaces/common';
import {
	IAddressSuggestion,
	ICfUserAddress,
	IReverseGeocoding
} from '@/types/interfaces/chocofood';
import { ITown } from '@/types/interfaces/common/address';
import { IDeliveryTariff } from '@/types/interfaces/delivery';
import { Subsidy } from '@/types/interfaces/menu';

export interface IDelivertZoneResponse {
	is_in_delivery_zone: boolean;
	delivery_cost: number;
}
export interface IState {
	tempAddress: ICfUserAddress;
	activeAddress: ICfUserAddress;
	userAddressList: ICfUserAddress[];
}

export interface IBaseDeliveryService {
	fetchAddressSuggestions: (address: string) => Promise<IAddressSuggestion[]>;
	fetchAddressByCoordinates: (
		coordinates: ICoordinates
	) => Promise<IReverseGeocoding[]>;
	isPrivateResidence: () => boolean;
	setTempTown: (town: string) => void;
	getAddressString: (
		address: ICfUserAddress,
		keys?: Array<keyof ICfUserAddress>
	) => string;

	hasAvailableAddress: () => boolean;
	getRedirectUrl: () => string;
	setRedirectUrl: (url: string) => void;
	clearRedirectUrl: () => void;
}

export interface DeliveryZonePayload {
	brandId?: string;
	locationId?: string;
	coordinates: ICoordinates;
}

export interface IDeliveryService extends IBaseDeliveryService {
	state: IState;
	getTown: ComputedRef<ITown>;
	activeAddressCoordinates: ComputedRef<ICoordinates>;
	setTempAddress: (address: Partial<ICfUserAddress>) => void;
	setActiveAddress: (address: ICfUserAddress) => void;
	clearTempAddress: () => void;
	clearActiveAddress: () => void;
	createAddress: (
		address: ICfUserAddress
	) => Promise<ICfUserAddress | undefined>;
	editUserAddress: (id: number, address: ICfUserAddress) => Promise<void>;
	deleteAddress: (id: number) => Promise<void>;
	fetchUserAddresses: () => Promise<ICfUserAddress[]>;
	initUserAddresses: () => Promise<void>;
	needToConfirmAddress: () => boolean;
	confirmAddress: () => void;
	fetchTariffs: (originCoordinates: ICoordinates) => Promise<IDeliveryTariff[]>;
	checkDeliveryZone: (
		payload: DeliveryZonePayload
	) => Promise<IDelivertZoneResponse>;
	fetchSubsidies: (filialId: number) => Promise<Subsidy>;
	getDeliverySubsidyDiscount: (sum: number, subsidies: Subsidy) => number;
}

export enum KEYS {
	IS_ADDRESS_CONFIRMED_KEY = 'isAddressConfirmed',
	REDIRECT_URL_KEY = 'addressRedirectUrl'
}
